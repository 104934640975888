const privacyData = {
  'cn': [
    {
      "topTitle": "《无限觉醒》隐私条款"
    },
    {
      "title": '前言',
      "content": [
        '本政策是由我方平台（以下称为“甲方”）就甲方在其和/或其关联公司的移动手机游戏平台上所提供的产品和服务（包括甲方的网站以及甲方现在正在提供和将来可能向用户提供的游戏服务和其他网络服务，以下统称为“产品和服务”）所制定的。',
        '最近更新日期：2022年10月31日',
        `本政策将帮助您了解以下内容：`,
        `一、我们如何收集和使用个人信息`,
        `二、我们如何使用 Cookie 和同类技术`,
        `三、我们如何共享、转让、公开披露个人信息`,
        `四、我们如何保护您的个人信息储存`,
        '五、第三方服务',
        `六、我们如何处理未成年人的个人信息`,
        `七、您的个人信息如何在全球范围转移`,
        `八、本政策可能变更`,
        `九、如何联系我们`,
        '在使用我们的服务前，您可以通过阅读完整版《无限觉醒隐私政策》，了解个人信息类型与用途的对应关系等更加详尽的个人信息处理规则，请您务必仔细阅读并透彻理解本政策，您应重点阅读并在确认充分理解及同意后再开始使用。如果您继续使用我们的服务，即表示您同意我们按照本政策收集、使用、储存和披露您的相关信息。',
        '请注意，甲方可能适时对本协议进行修改与更新，您同意将随时留意查看本协议的最新版本。请在使用我们的产品（或服务）前，仔细阅读并了解本《隐私政策》，以确保对本《隐私政策》的最新版本始终保持了解。',
      ]
    },
    {
      'title': '一、我们如何收集和使用个人信息',
      "content": [
        '1.个人信息',
        '当您使用服务时，我们会收集您的网络身份标识信息及个人常用设备信息，用于标记您为无限觉醒的用户。为完成创建账号所填写的信息，例如：您的手机号码、身份证、创建的用户名。第三方可能会获取您的社交帐号信息。',
        '2.为您提供您可能感兴趣的广告、资讯及产品信息',
        '为向您提供与您更加相关的广告以替代普遍投放的广告 ，我们可能会收集和使用您在甲方的操作记录和部分必要的设备信息（例如：您的唯一设备识别码），以及您在使用我们服务时浏览或要求提供的其他信息和内容详情、游戏账号交易信息。',
        '3.支付结算',
        '为便于您在支付相关订单时，综合判断您账户及交易风险、进行身份验证、检测及防范安全事件，我们可能会在交易过程中收集您的虚拟财产相关信息（仅限交易记录、虚拟货币、虚拟交易、游戏类兑换码等）。',
        '4.消费记录',
        '当您使用无限觉醒产品的消费功能时，我们可能会收集您的充值记录、消费记录信息，以便您查询您的交易记录，同时尽最大程度保护您的虚拟物品安全。充值记录、消费记录属于敏感信息，收集上述信息为实现无限觉醒的消费功能所必须，否则将无法完成交易。',
        '5.产品开发和服务优化。',
        '例如，为修复系统故障并优化我们的服务，我们可能需记录和分析系统故障时产生的信息。',
        '6.可能会收集的信息',
        '为保障您正常使用我们的服务，维护游戏基础功能的正常运行，优化游戏产品性能，提升您的游戏体验并保障您的帐号安全，我们可能会收集：唯一设备识别符（IMEI）、MAC地址、广告标识符、Serial、设备机型、设备名称、操作系统及版本、设备分辨率、设备设置、软硬件特征信息、设备屏幕尺寸、移动网络信息、登录IP地址、日志信息（包括操作日志、服务日志）、CPU信息、软件信息。用于识别帐号异常状态，了解产品适配性，维护基础功能的正常运行，实现本游戏的问题排查。为了收集上述基本的 个人设备信息，我们将会申请访问您的设备信息权限，我们收集这些信息是为了向您提供本游戏的核心游戏功能。拒绝提供上述信息将可能使您无法正常体验本游戏的功能。',
        '7.第三方收集的信息',
        '请您理解，在从第三方接收您的个人信息前，我们无权决定且无法知悉第三方获取您个人信息的范围，如第三方开展业务所进行的个人信息处理活动超出您向我们提供个人信息时的授权同意范围、或第三方违法违规收集您的个人信息（如渠道违反必要原则，收集您的通讯录信息），责任应由第三方承担，请您立即和我们取得联系，以便我们及时通知第三方采取相应措施以避免或降低相关损失。',
      ]
    },
    {
      "title": "二、我们如何使用 Cookie 和同类技术",
      "content": [
        '为确保网站正常运转，我们可能会在您的计算机或移动设备上存储名为 Cookie 的小数据文件。Cookie 通常包含标识符、站点名称以及一些号码和字符。 Cookie有助于我们辨认您作为我们的注册用户的身份，或保存您向我们提供有关您的喜好或其他信助于我们辨认您作为我们的注册用户的身份，或保存您向我们提供有关您的喜好或其他信息了解您使用服务进行什么活动、或哪些服务或服务最受欢迎；及有助于我们根据您的信息，向您提供与您相关的广告而非进行普遍的广告投放。',
        '无限觉醒上可能会有广告商和其他合作方放置的COOKIES或同类技术。这些COOKIES和或同类技术可能会收集与您相关的非个人身份信息，以用于分析用户如何使用该等服务、向您发送您可能感兴趣的广告，或用于评估广告服务的效果。这些第三方COOKIES或同类技术收集和使用该等信息不受本政策约束，而是受到其自身的信息保护声明约束，我们不对第三方的COOKIES或同类技术承担责任。',
      ]

    },
    {
      "title": "三、我们如何共享、转让、公开披露您的个人信息",
      "content": [
        '（一）共享',
        '您理解并同意，经过去标识化处理后的信息将无法识别您的身份，我们可能在以下情况下与甲方以外的任何公司、组织和个人分享您的个人信息：',
        '1.与关联方的必要分享。为便于我们基于统一的帐号体系向您提供一致化服务以及便于您进行统一管理、个性化推荐、保障系统和帐号安全等，您的个人信息可能会在我们和我们的关联方之间进行必要共享；',
        '2.我们可能会根据法律法规规定，或按政府主管部门的强制性要求，对外共享您的个人信息。',
        '3.向委托我们进行推广的合作伙伴等第三方共享，目的是为了使该等委托方了解推广的覆盖面和有效性。比如我们可以告知该委托方有多少人看了他们的推广信息或在看到这些信息后购买了委托方的商品，或者向他们提供不能识别个人身份的统计信息，帮助他们了解其受众或顾客；',
        '4.与授权合作伙伴共享：仅为实现本政策中声明的目的，我们的某些服务将由授权合作伙伴提供。我们可能会与合作伙伴共享您的某些个人信息，以提供更好的客户服务和用户体验。我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供服务所必要的个人信息。',
        '（二）转让',
        '我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：',
        '1.在获取明确同意的情况下转让：获得您的明确同意后，我们会向其他方转让您的个人信息；',
        '2.在涉及合并、收购或破产清算时，如涉及个人信息转让，我们将通过推送通知、公告或其他方式另行告知您相关情形，按照法律法规所要求的标准继续保护或要求新的管理者按照本政策继续保护您的个人信息。',
        '3.部分场景下，您可以使用相同的账号及密码在其他第三方平台登录、使用，以获得更好的游戏体验。为提供此项服务，我们可能需要向第三方传送您的个人信息及游戏信息，我们将按照法律法规所要求的标准继续保护或要求新的管理者按照本政策继续保护您的个人信息。',
        '（三）公开披露',
        '我们仅会在以下情况下，公开披露您的个人信息：',
        '1.获得您明确同意后；',
        '2.与国家安全、国防安全有关的；',
        '3.与公共安全、公共卫生、重大公共利益有关的；',
        '4.与犯罪侦查、起诉、审判和判决执行等有关的；',
        '5.出于维护信息主体或其他个人的生命、财产等重大合法权益但又很难得到您本人同意的；',
        '6.所收集的信息是您自行向社会公众公开的；',
        '7.从合法公开披露的信息中收集信息的，如合法的新闻报道、政府信息公开等渠道；',
        '8.根据您的要求签订合同所必需的；',
        '9.用于维护甲方服务的安全稳定运行所必需的，例如发现、处置产品或服务的故障；',
        '10.为合法的新闻报道所必需的；',
        '11.学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的信息进行去标识化处理的；',
        '12.法律法规规定的其他情形。',
      ]

    },
    {
      "title": "四、我们如何保护您的个人信息",
      "content": [
        '（一）我们会采取一切合理可行的措施，保护您的个人信息。例如，我们使用SSL/hash对您的数据进行加密保护；如我们与第三方分享您的信息，我们可能将会采用加密、脱敏及其他去标识化处理等手段保障您的信息安全。',
        '（二）我们仅允许有必要知晓这些信息的甲方工作人员、合作伙伴访问您的信息，同时要求可能接触到您的信息的所有人员履行相应的保密义务。如果未能履行这些义务，可能会被追究法律责任或被中止与甲方的合作关系。',
        '（三）我们会采取一切合理可行的措施，确保未收集无关的个人信息。我们只会在必要的合理期限内保留您的个人信息，除非需要延长保留期或受到法律的允许。',
        '（四）互联网并非绝对安全的环境，而且电子邮件、即时通讯、及与其他甲方用户的交流方式并未加密，我们强烈建议您不要通过此类方式发送个人信息。请使用复杂密码，协助我们保证您的账号安全。请您务必妥善保管好您的帐号、密码及其他身份要素。您在使用无限觉醒时，我们会通过您的帐号、密码及及其他身份要素来识别您的身份。',
        '（五）互联网环境并非百分之百安全，我们将尽力确保或担保您发送给我们的任何信息的安全性。如果我们的物理、技术、或管理防护设施遭到破坏，导致信息被非授权访问、公开披露、篡改、或毁坏，导致您的合法权益受损，我们将承担相应的法律责任。',
        '（六）请您理解，由于技术的限制以及风险防范的局限，即便我们已经尽量加强安全措施，也无法始终保证信息百分之百的安全。您需要了解，您接入无限觉醒服务所用的系统和通讯网络，有可能因我们可控范围外的情况而发生问题。',
      ]

    }, {
      "title": "五、第三方服务",
      "content": [
        '无限觉醒可能链接至第三方提供的社交媒体或其他服务（包括网站或其他服务形式）。使用SDK名称：友盟SDK，服务类型：社会化分享，收集个人信息类型：设备信（IMEI/MAC/Android ID/IDFA/OpenUDID/GUID/SIM卡IMSI/IP/地理位置） 还包括：（1）您可利用“分享”键将某些内容分享到第三方服务，或您可利用第三方服务登录无限觉醒。这些功能可能会收集您的信息（包括您的日志信息），并可能在您的电脑装置COOKIES，从而正常运行上述功能； （2）我们通过广告或我们服务的其他方式向您提供链接，使您可以链接至第三方的服务或网站； （3）其他接入第三方服务的情形。包括：为实现本政策中声明的目的，我们可能会接入第三方SDK服务，并将我们依照本政策收集的您的个人信息如社交媒体信息，共享给该等第三方，以便提供更好的客户服务和用户体验。 ',
        '该等第三方社交媒体或其他服务由相关的第三方负责运营。您使用该等第三方的社交媒体服务或其他服务（包括您向该等第三方提供的任何信息），须受第三方自己的服务条款及信息保护声明（而非本政策）约束，您需要仔细阅读其条款。本政策仅适用于我们所收集的个人信息，并不适用于任何第三方提供的服务或第三方的信息使用规则。 您发现这些第三方社交媒体或其他服务存在风险时，建议您终止相关操作以保护您的合法权益。 友盟隐私政策链接：https://www.umeng.com/page/policy'
      ]

    },
    {
      "title": "六、我们如何处理未成年人的个人信息",
      "content": [
        '我们的产品、网站和服务主要面向成人。甲方遵从国家政策，如果没有父母或监护人的同意，未成年人不得创建自己的用户账户。对于经父母或监护人同意而收集未成年人个人信息的情况，我们只会在受到法律的允许、父母或监护人明确同意或者保护未成年人所必要的情况下使用或公开披露此信息。如发现在未事先获得法定监护人同意的情况下，我们可能收集了未成年人的个人信息，我们会对该等数据和个人信息尽快进行删除等操作处理。',
        '若您是未成年人的监护人，当您对您所监护的未成年人的个人信息有相关疑问时，请及时与我们的客服联系。',
      ]

    },
    {
      "title": "七、您的个人信息如何在全球范围转移",
      "content": [
        '原则上，我们在中华人民共和国境内收集和产生的个人信息，将存储在中华人民共和国境内。',
        '我们可能通过遍布全球的资源和服务器提供产品或服务，这意味着，在获得您的授权同意后，您的个人信息可能会被转移到您使用产品或服务所在国家/地区的境外管辖区，或者受到来自这些管辖区的访问。',
        '境外管辖区可能有不同的个人信息保护法，我们将对信息进行加密传输，并尽商业上合理努力督促第三方在使用您的个人信息时遵守其适用的法律法规及协议约定的保密和安全措施。',
      ]

    },
    {
      "title": "八、本政策可能变更",
      "content": [
        '为了给您提供更好的服务，我们可能会根据产品或服务的更新情况及法律法规的相关要求适时修改本政策的条款，该等修改构成本政策的一部分。如该等更新造成您在本政策下权利的实质减少或重大变更，我们将在本政策生效前通过网站公告、推送通知、弹窗提示或其他方式来通知您，您如果不同意该等变更，可以选择停止使用无限觉醒；如您仍然继续使用无限觉醒，即表示您已充分阅读、理解并同意受经修订的本政策的约束。',
      ]

    },
    {
      "title": "九、如何联系我们",
      "content": [
        '我们设立了个人信息保护专职部门，将按照本政策保护您的个人信息。如您有关于网络信息安全的投诉和举报，或您对本政策、您的信息的相关事宜有任何问题、意见或建议，请与无限觉醒的个人信息保护工作人员联系，我们将在15个工作日内回复。',
        `客服QQ：485729307`,
        `联系邮箱：485729307@qq.com`,
        `官方交流群: 220714787`,
      ]
    },
  ],
};

export { privacyData };
